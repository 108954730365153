var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Pand dupliceren"}},[_c('FormulateForm',{attrs:{"name":"propertyDuplication"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"text","name":"reference","label":"Referentie","placeholder":"Voer nieuwe referentie in","validation":"bail|required:trim|validatePropertyReference","outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"select","name":"transaction_type","label":"Transactietype","options":_vm.PROPERTY_TRANSACTION_TYPES,"value":_vm.property.transaction_type,"validation":"required","outer-class":"tw-my-0"}})],1),_c('FormulateInput',{attrs:{"type":"select","name":"duplication_reason","label":"Reden voor duplicatie","placeholder":"Selecteer een reden","validation":"required","options":{
        deduplication: 'Ontdubbeld dossier (commerciële doeleinden)',
        new_dossier: 'Oud dossier terug in verkoop/verhuur',
        newly_built: 'Nieuwbouw'
      }}}),_c('fieldset',{staticClass:"tw-border-none tw-p-0 tw-m-0 tw-mt-4"},[_c('div',{staticClass:"tw-my-1 tw-flex tw-flex-wrap tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"checkbox","name":"owners","label":"Eigenaars","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"checkbox","name":"renters","label":"Huurders","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"checkbox","name":"buyers","label":"Kopers","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"checkbox","name":"notaries","label":"Notarissen","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"checkbox","name":"pictures","outer-class":"tw-m-0"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
      var id = ref.id;
      var classes = ref.classes;
return [_c('label',{class:classes.label,attrs:{"for":id}},[_vm._v(" Afbeeldingen ")]),_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(" (Dit gebeurt in de achtergrond en kan even duren) ")])]}}],null,true)}),_c('FormulateInput',{attrs:{"type":"checkbox","name":"files","outer-class":"tw-m-0"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
      var id = ref.id;
      var classes = ref.classes;
return [_c('label',{class:classes.label,attrs:{"for":id}},[_vm._v(" Bestanden ")]),_c('span',{staticClass:"tw-text-xs tw-text-error"},[_vm._v(" (Dit gebeurt in de achtergrond en kan even duren) ")])]}}],null,true)})],1)]),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":['tw-float-right']}},[_c('i',{class:[
          'fas tw-mr-1',
          isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
        ]}),_vm._v(" Dupliceren ")])]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }