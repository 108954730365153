<template>
  <BaseModal ref='modal' title="Pand dupliceren">
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="propertyDuplication"
      @submit="submit"
    >
      <div class="tw-grid md:tw-grid-cols-2 tw-gap-2">
        <FormulateInput
          type="text"
          name="reference"
          label="Referentie"
          placeholder="Voer nieuwe referentie in"
          validation="bail|required:trim|validatePropertyReference"
          outer-class="tw-my-0"
        />
        <FormulateInput
          type="select"
          name="transaction_type"
          label="Transactietype"
          :options="PROPERTY_TRANSACTION_TYPES"
          :value="property.transaction_type"
          validation="required"
          outer-class="tw-my-0"
        />
      </div>
      <FormulateInput
        type="select"
        name="duplication_reason"
        label="Reden voor duplicatie"
        placeholder="Selecteer een reden"
        validation="required"
        :options="{
          deduplication: 'Ontdubbeld dossier (commerciële doeleinden)',
          new_dossier: 'Oud dossier terug in verkoop/verhuur',
          newly_built: 'Nieuwbouw'
        }"
      />
      <fieldset class="tw-border-none tw-p-0 tw-m-0 tw-mt-4">
        <div class="tw-my-1 tw-flex tw-flex-wrap tw-gap-2">
          <FormulateInput
            type="checkbox"
            name="owners"
            label="Eigenaars"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="checkbox"
            name="renters"
            label="Huurders"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="checkbox"
            name="buyers"
            label="Kopers"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="checkbox"
            name="notaries"
            label="Notarissen"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="checkbox"
            name="pictures"
            outer-class="tw-m-0"
          >
            <template #label="{ id, classes }">
              <label :for="id" :class="classes.label">
                Afbeeldingen
              </label>
              <span class="tw-text-xs tw-text-error">
                (Dit gebeurt in de achtergrond en kan even duren)
              </span>
            </template>
          </FormulateInput>
          <FormulateInput
            type="checkbox"
            name="files"
            outer-class="tw-m-0"
          >
            <template #label="{ id, classes }">
              <label :for="id" :class="classes.label">
                Bestanden
              </label>
              <span class="tw-text-xs tw-text-error">
                (Dit gebeurt in de achtergrond en kan even duren)
              </span>
            </template>
          </FormulateInput>
        </div>
      </fieldset>
      <FormulateErrors class="tw-text-right" />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        :outer-class="['tw-float-right']"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Dupliceren
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { PROPERTY_TRANSACTION_TYPES } from '@/forms/selectOptions'
import { duplicateProperty } from '@/services/properties'
import { successModal } from '@/modalMessages'

export default {
  name: 'PropertyDuplication',
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  constants: {
    PROPERTY_TRANSACTION_TYPES
  },
  data () {
    return {
      values: {}
    }
  },
  methods: {
    show () {
      this.values = {
        owners: true,
        renters: false,
        buyers: false,
        notaries: false,
        pictures: true,
        files: false,
        transaction_type: this.property.transaction_type
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    async submit (data) {
      try {
        const response = await duplicateProperty(this.property.id, data)
        const newPropertyId = response.data?.id
        this.hide()
        successModal('Het pand is succesvol gedupliceerd')
        this.$router.push({ name: 'PropertyDetails', params: { id: newPropertyId } })
        return response
      } catch (error) {
        this.$formulate.handle(error, 'propertyDuplication')
      }
    }
  }
}
</script>
